.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}

.loading-text {
    color: #374151;
    /* gray-700 */
    font-size: 1em;
}

.loading-dots {
    display: flex;
    gap: 0.5em;
    justify-content: center;
}

.dot {
    width: 0.75em;
    height: 0.75em;
    background-color: rgb(0, 162, 233);
    /* teal-500 color */
    border-radius: 50%;
    animation: bounce 0.5s infinite;
}

.dot:nth-child(2) {
    animation-delay: 0.15s;
}

.dot:nth-child(3) {
    animation-delay: 0.3s;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}
/* 整体页面背景设置 */
body {
    margin: 0;
    padding: 0;
    font-family: "Helvetica Neue", Arial, sans-serif;
    background: linear-gradient(to bottom, #06A2EA 0%, #E9F8FF 35%);
}

/* 顶部区域渐变背景及标题 */
.header {
    top: 20px;
    margin-bottom: 2rem;
    padding: 3rem;
    text-align: center;
    color: #fff;
}

/* 标题文案 */
.header h1 {
    margin-top: 1rem;
    font-size: 1.9rem;
    color: aliceblue;
    text-align: left;
    font-weight: normal;
}

.header img {
    position: absolute;
    right: 1rem;
    top: 2rem;
    width: 16rem;
    height: auto;
    mix-blend-mode: luminosity;
    /* 或其他模式，如 multiply, screen, overlay 等 */
    opacity: 0.9;
}


/* 卡片容器 */
.card {
    max-width: 35rem;
    max-height: 28rem;
    width: 100%;
    margin: 2rem;
    padding-top: 2rem;
    background: #fff;
    border-radius: 1.2rem;
    box-shadow: 0 .4rem .6rem rgba(0, 0, 0, 0.1);
    overflow: hidden;
    color: #2c3e50;
}

/* 顶部说明区域 */
.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* 左侧icon和标题区域 */
.card-header-left {
    display: flex;
    align-items: center;
}

/* 保字图标(可自行替换为背景图标或SVG) */
.card-header-left .icon {
    display: inline-block;
    margin-left: 1.5rem;
    width: 2.4rem;
    height: 2.4rem;
    background: url(../../material/warranty_icon/质保.png) no-repeat center center;
    /* 根据实际需求替换 */
    background-size: contain;
    margin-right: .8rem;
}

/* 标题文字 */
.card-header-left .title {
    font-size: 1.7rem;
    font-weight: bolder;
}

/* 标记有效期的标签 */
.card-header-right {
    border: .2rem solid #00A0E9;
    color: #00A0E9;
    width: 6rem;
    height: 2.4rem;
    text-align: center;
    padding: .2rem .4rem;
    margin-left: .5rem;
    font-size: 11px;
    font-weight: bold;
    border-radius: 1.55rem;
}

/* 标记有效期的标签 */
.card-header-right-expire {
    border: .2rem solid #6b7280;
    color: #6b7280;
    width: 6rem;
    height: 2.4rem;
    text-align: center;
    padding: .2rem .4rem;
    margin-left: .5rem;
    font-size: 1.1rem;
    font-weight: bold;
    border-radius: 1.55rem;
}

/* 修理产品信息 */
.card-product {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
    margin-left: 4.7rem;
    color: #333;
    font-weight: bold;
}

/* 日期信息区域 */
.card-dates {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1rem;
}

/* 单个日期块 */
.card-date-item {
    display: inline-block;
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    color: #333;
}

/* 日期上方文字（开始时间、到期时间） */
.card-date-item .label {
    display: flex;
    font-size: 1.3rem;
    font-weight: light;
    color: #999;
    margin-top: .3rem;
}

/* 说明图标(如截图中"?"标记) */
.card-date-item img {
    text-align: center;
    width: 1.4rem;
    height: 1.4rem;
}


/* 底部按钮区域 */
.card-actions {
    justify-content: space-evenly;
    display: flex;
    position: relative;
    align-items: center;
    background: #00A0E9;
    /* 蓝色背景，与右侧风格一致 */
    padding: 1rem 0;
    border-top: none;
    /* 移除白色顶部边框 */
}

/* 按钮样式 */
.card-actions .card-actions-item {
    text-decoration: none;
    font-size: 1.4rem;
    color: #fff;
    /* 白色文字 */
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    /* 每个按钮平分空间 */
}

/* 图标样式：使用SVG或背景图片代替原来的圆形背景 */
.card-actions .card-actions-item .wrench-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-right: .4rem;
    background: url(../../material/warranty_icon/维修.png) no-repeat center center;
    background-size: contain;
    background-color: #00A0E9;
}

.card-actions .card-actions-item .extend-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-right: .4rem;
    background: url(../../material/warranty_icon/延长质保.png) no-repeat center center;
    background-size: contain;
    background-color: #00A0E9;
}

/* 在两个按钮之间添加一条分割线 */
.card-actions::before {
    content: "";
    position: absolute;
    width: .1rem;
    height: 2.4rem;
    background: #fff;
    /* 白色分割线 */
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


/* 标题下方说明(如"全面保障·一站式服务")可在header中添加 */
.sub-header {
    font-size: .9rem;
    margin-top: .5rem;
    opacity: 0.9;
}
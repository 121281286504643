
/* .panel-row 样式 */
.panel-row {
  display: inline-block;
}

/* 定义不同保修状态的颜色 */
.expire {
  color: #6b7280; /* 灰色 */
}

.default {
  color: #3b82f6; /* 蓝色 */
}

.extend {
  color: #10b981; /* 绿色 */
}

.expiring {
  color: goldenrod;
}
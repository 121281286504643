.warranty-drawer .ant-drawer-content {
    border-radius: 1.6rem 1.6rem 0 0;
    box-shadow: 0 -0.4rem 1.2rem rgba(0, 0, 0, 0.1);
  }
  
  .warranty-drawer .ant-drawer-header {
    border-bottom: .1rem solid #f0f0f0;
    padding: 1.6rem 2.4rem;
  }
  
  .drawer-title {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }
  
  .title-text {
    font-size: 1.8rem;
    font-weight: 600;
    color: #1f2937;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20rem;
  }
  
  .warranty-content {
    padding: 2.4rem 0;
  }
  
  .status-section {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-bottom: 2.4rem;
    padding: 1.6rem;
    background-color: #f9fafb;
    border-radius: 1.2rem;
  }
  
  .status-icon {
    width: 2.8rem;
    height: 2.8rem;
  }
  
  .status-text {
    font-size: 1.6rem;
    font-weight: 500;
  }
  
  .warranty-descriptions {
    background-color: white;
  }
  
  .warranty-descriptions .ant-descriptions-item {
    padding: 1.6rem 2.4rem;
  }
  
  .warranty-descriptions .ant-descriptions-item-label {
    font-weight: 500;
    color: #4b5563;
    background-color: #f9fafb;
  }
  
  .warranty-descriptions .ant-descriptions-item-content {
    color: #1f2937;
  }
  
  .description-item {
    transition: background-color 0.2s ease;
  }
  
  .description-item:hover {
    background-color: #f9fafb;
  }

  /* Drawer 标题 */
.drawer-title {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
}

/* 服务描述部分 */
.service-row {
  margin-bottom: 2rem;
}

.service-col {
  text-align: center;
}

.service-icon {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: .8rem;
}

.service-description {
  font-size: 1.1rem;
  color: #666;
}

/* 延保服务选项部分 */
.warranty-row {
  margin-bottom: 2rem;
}

.warranty-col {
  text-align: center;
}

.warranty-card {
  border: .1rem solid #eaeaea;
  border-radius: .8rem;
  padding: 1rem;
  background-color: #fafafa;
}

.warranty-title {
  font-size: 1.5rem;
  font-weight: bolder;
  margin-top: .2rem;
  margin-bottom: .8rem;
}

.warranty-details {
  font-size: 1.1rem;
  color: #CDB99C;
  margin-bottom: 1rem;
}

/* 容器样式 */
.price-container {
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: center;
  align-items: baseline; /* 对齐方式，确保文字与数字对齐 */
  font-family: Arial, sans-serif; /* 设置字体 */
  color: #7C5534; /* 文字颜色（棕色） */
}

/* 左侧文字“服务价格”样式 */
.price-label {
  font-size: 1rem; /* 设置字体大小 */
  color: #666; /* 设置灰色 */
  margin-right: 4px; /* 与数字保持一定间距 */
}

/* 价格数字样式 */
.price-value {
  font-size: 2rem; /* 设置字体大小，比文字略大 */
  font-weight: bold; /* 加粗 */
  color: #704220; /* 设置棕色 */
}

/* 单位“元”样式 */
.price-unit {
  font-size: 1rem; /* 设置字体大小，与文字“服务价格”一致 */
  color: #666; /* 设置灰色 */
  margin-left: 4px; /* 与数字保持一定间距 */
}

.warranty-button {
  width: 100%;
}

/* 客服信息部分 */
.contact-row {
  text-align: center;
}

.contact-info {
  font-size: 1.4rem;
  color: #666;
}

.contact-number {
  font-size: 1.6rem;
  font-weight: bold;
}

.icon-container {
  display: flex;
  flex-direction: column; /* 垂直排列图标和文字 */
  align-items: center; /* 水平居中 */
  justify-content: center; /* 垂直居中 */
  text-align: center; /* 文本居中 */
  width: 100px; /* 图标容器宽度 */
  margin: 0 auto; /* 居中对齐 */
}

.icon-container .circle-icon-0 {
  justify-content: center;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: #CDB99C;
  background-image: url('../../material/warranty_icon/全面保障.png'); /* 引用 public 文件夹的图片 */
  background-size: 65%; /* 图片大小设置为圆的 80% */
  background-position: center; /* 图片靠上显示 */
  background-repeat: no-repeat; 
}
.icon-container .circle-icon-1 {
  justify-content: center;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: #CDB99C;
  background-image: url('../../material/warranty_icon/费用无忧.png'); /* 引用 public 文件夹的图片 */
  background-size: 65%; /* 图片大小设置为圆的 80% */
  background-position: center;
  background-repeat: no-repeat; 
}
.icon-container .circle-icon-2 {
  justify-content: center;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: #CDB99C;
  background-image: url('../../material/warranty_icon/一站式服务.png'); /* 引用 public 文件夹的图片 */
  background-size: 65%; /* 图片大小设置为圆的 80% */
  background-position: center; 
  background-repeat: no-repeat; 
}